const Data = {
    presentations: [
        "Mental status change/altered mental status",
        "Chest pain",
        "Vomiting",
        "Weakness",
        "Back Pain",
        "Acute Cough",
        "Headache",
        "Abdominal pain",
        "Lightheadedness/Syncope",
        "Fever",
        "Diarrhea",
        "Dyspnea",
        "Gastrointestinal Bleeding",
        "Ankle pain",
        "Dermatitis",
        "Fatigue",
        "Male genitourinary symptoms (BPH- ED)",
        "Shoulder pain",
        "Otalgia",
        "Rhinitis/sinusitis",
        "Pharyngitis",
        "Dysuria",
        "Vaginal discharge",
        "Rash/Skin lesion",
        "Dehydration"
    ],
    procedures: {
        "Resuscitation": [
            "Bag valve mask ventilation",
            "Basic life support",
            "Intravenous line insertion",
            "Central line insertion",
            "Intraosseous line insertion"
        ],
        "Standard precautions": [
            "Handwashing",
            "Donning and doffing PPE"
        ],
        "Health maintenance": [
            "Adult well care",
            "Prenatal care",
            "Senior Well care",
            "Adolescent Well Care",
            "Child Well Care",
            "Discuss recommended cancer screening",
        ],
        "Care related to hospitalization": [
            "Discharge planning",
            "Care coordination",
            "Hospital or ED follow-up",
            "Pre-operative evaluation",
            "Intrapartum Care of Patient (Labor and Delivery)",
            "Postpartum Care of Patient",
            "Weight based medication dosing",
            "End-of-life care",
            "Palliative care",
            "Hospice care",
            "Post-operative care",
        ],
        "Obtain fluid for diagnostic evaluation": [
            "Lumbar puncture",
            "Venous access",
            "Bladder catheterization",
            "Bladder tap",
            "Peritoneal tap",
            "Access a central line",
        ],
        "Labor and delivery": [
            "Cesarean section",
            "Vaginal Delivery",
        ],
        "Shared decision-making": [
            "Vaccine review and counseling",
            "Advance directive preparation",
            "Contraception counseling and management",
            "Motivational interviewing to address behavior change",
        ],
        "Incisions, insertions, and repair": [
            "Laceration repair",
            "Laparoscopic surgery",
            "Open abdominal surgery",
            "Wound care ",
            "Central line placement",
        ],
        "Sterile technique": [
            "Surgical hand scrubbing",
            "Gowning",
            "Gloving",
            "Establish a sterile field",
            "Maintain a sterile field"
        ]
    },
    conditions: {
        "Anxiety disorders": [
            "Obsessive-compulsive disorder",
            "Generalized anxiety disorder",
            "Panic disorder",
            "Post-traumatic stress disorder",
            "Phobias",
            "Separation anxiety",
            "Selective mutism",
            "Substance-induced anxiety disorder",
            "Anxiety disorder due to a medical condition"
        ],
        "Mood disorders": [
            "Depression",
            "Dysthymia",
            "Bipolar disorder",
            "Substance-induced mood disorder",
            "Mood disorder due to a medical condition",
            "Suicidal ideation",
            "Peripartum mood disorder",
            "Self-harm"
        ],
        "Psychotic disorders": [
            "Schizophrenia",
            "Schizoaffective Disorder",
            "Schizophreniform Disorder",
            "Brief Psychotic Disorder",
            "Delusional Disorder",
            "Substance-induced psychotic disorder",
            "Psychotic disorder due to a medical condition",
            "Peripartum psychosis"
        ],
        "Substance use and related disorders (including nicotine, alcohol, THC, or illicit substances)": [
            "Substance use disorders",
            "Intoxication",
            "Withdrawal",
            "Overdose"
        ],
        "Global central nervous system dysfunction": [
            "Delirium",
            "Dementia",
            "Altered level of consciousness",
            "Dizziness/Vertigo/Syncope"
        ],
        "Disorders of hemodynamic regulation": [
            "Ventricular arrhythmia",
            "Hypertension",
            "Hypotension",
            "Congestive heart failure",
            "Supraventricular Arrhythmia ",
            "Syncope",
            "Shock"
        ],
        "Sensory disorders": [
            "Vision loss",
            "Hearing loss",
            "Anosmia",
            "Numbness",
            "Paresthesias",
            "Tinnitus",
            "Chronic pain (e.g., headache, abdominal pain, back pain)",
            "Dizziness/Vertigo"
        ],
        "Trauma": [
            "Concussion",
            "Traumatic fracture",
            "Pneumothorax",
            "Hemothorax",
            "Cardiac tamponade",
            "Penetrating injury (e.g., stabbing, gunshot, impaling)",
            "Intracranial bleed",
            "Blunt abdominal injury",
            "Spinal cord injury",
            "Burn",
            "Intentional poisoning"
        ],
        "Toxin exposure": [
            "Accidental poisoning",
            "Carbon monoxide ",
            "Secondhand smoke",
            "Lead ",
            "Chemical, e.g., chlorine, acids, ammonia, pesticides, fertilizers"
        ],
        "Obstructive pulmonary disease": [
            "Asthma",
            "COPD"
        ],
        "Endocrine disorders": [
            "Diabetes, type I or type II",
            "Hypoglycemia",
            "Hyperglycemia",
            "Hyperthyroidism",
            "Hypothyroidism",
            "Hyperlipidemia",
            "Hypercholesterolemia",
            "Hypertrigliceridemia",
            "Dyslipidemia"
        ],
        "Disorders of gastrointestinal motility": [
            "Gastroesophageal reflux",
            "Constipation",
            "Pyloric stenosis",
            "Small Bowel Obstruction",
            "Ileus"
        ],
        "Genito-urinary infections and inflammation": [
            "Urinary tract infection",
            "Vulvovaginitis",
            "Prostatitis",
            "Pelvic inflammatory disease",
            "Urethritis",
            "Cervicitis"
        ],
        "Renal failure": [
            "Chronic kidney disease",
            "Acute renal insufficiency or failure",
            "Acute kidney injury"
        ],
        "Liver failure or dysfunction": [
            "Cirrhosis",
            "Acute liver failure",
            "Obstructive biliary disease (choledocholithiasis)",
            "Acetaminophen overdose",
            "Hepatitis",
            "Non-alcoholic fatty liver disease",
            "Neonatal hyperbilirubinemia"
        ],
        "Exocrine pancreatic insufficiency": [
            "Acute pancreatitis",
            "Chronic pancreatitis",
            "Traumatic pancreatitis",
            "Cystic fibrosis",
            "Pancreatic cancer"
        ],
        "Localized central nervous system dysfunction": [
            "CVA/TIA (Stroke)",
            "Ataxia",
            "Tremor",
            "Tic disorder",
            "Tourette disorder",
            "Multiple sclerosis",
            "Cerebral palsy"
        ],
        "Seizures": [
            "Febrile seizure",
            "Epilepsy",
            "Hypoglycemic seizure",
            "Post-traumatic seizure",
            "Post-syncopal seizure",
            "Iatrogenic seizure (ECT)"
        ],
        "Neurodegenerative disorders": [
            "Huntington's chorea",
            "Parkinson's spectrum disorders",
            "Dystonia",
            "Alzheimer's disease",
            "Mild cognitive impairment",
            "Dementia with Lewy bodies"
        ],
        "Peripheral nervous system and neuromuscular disorders": [
            "Guillain-Barre",
            "Carpal tunnel syndrome",
            "Diabetic neuropathy",
            "Amyotrophic lateral sclerosis",
            "Peripheral nerve injury",
            "Bell's palsy",
            "Neuropathy secondary to medication/chemotherapy"
        ],
        "Disorders of menstruation": [
            "Dysmenorrhea",
            "Amenorrhea: Primary or Secondary",
            "Menorrhagia"
        ],
        "Family planning and management": [
            "Preconception counseling",
            "Fertility concerns",
            "Miscarriage management",
            "Abortion care",
            "Abnormal Labor"
        ],
        "Disorders of labor": [
            "Post-dates Pregnancy",
            "Premature/Preterm Rupture of Membranes (PROM/PPROM)",
            "Placental abruption",
            "Fetal distress",
            "Hypertensive Disorder of Pregnancy"
        ],
        "Disorders of pregnancy": [
            "Gestational diabetes",
            "Placenta previa",
            "Hyperemesis gravidarum",
            "Preeclampsia",
            "Allergic reaction"
        ],
        "Allergy": [
            "Atopic dermatitis",
            "Anaphylaxis",
            "Allergic rhinitis",
            "Allergic conjunctivitis",
            "Allergy-induced bronchospasm/wheeze",
            "Eczema",
            "Autism spectrum disorder"
        ],
        "Disorders of growth and development": [
            "Developmental delay",
            "Congenital disorders",
            "Fetal alcohol spectrum disorder",
            "Failure to thrive",
            "Expressive language delay",
            "Motor delay",
            "Intellectual disability",
            "ADHD",
            "Learning disability",
            "Child abuse"
        ],
        "Interpersonal mistreatment or abuse": [
            "Bullying",
            "Intimate partner violence",
            "Assault",
            "Abuse of a vulnerable adult",
            "Neglect of a child or vulnerable adult",
            "Munchausen's by proxy",
            "Hypoxemia"
        ],
        "Respiratory insufficiency or failure": [
            "Dyspnea",
            "Spontaneous pneumothorax",
            "Inhaled foreign body",
            "Choking",
            "Vocal cord dysfunction",
            "Hypercarbia",
            "Drowning",
            "Inhalation injury",
            "Pleural effusion",
            "Acute myocardial infarction"
        ],
        "Vascular obstruction": [
            "Acute coronary syndrome",
            "Deep Vein Thrombosis (DVT)",
            "Pulmonary Embolism",
            "Hypo/hyper-natremia"
        ],
        "Disorders of electrolyte balance": [
            "Hypo/hyper-kalemia",
            "Hypo/hyper-calcemia",
            "Hypo/hyper-chloremia",
            "Hypo/hyper-magnesemia",
            "Hypo/hyper-phosphatemia ",
            "Respiratory or metabolic acidosis",
            "Respiratory or metabolic alkalosis",
            "Pneumonia"
        ],
        "Respiratory infection or inflammation": [
            "Tracheitis",
            "Croup",
            "Bronchitis",
            "Aspiration pneumonitis",
            "Umbilical hernia"
        ],
        "Herniation of the gastrointestinal tract": [
            "Hiatal hernia",
            "Inguinal hernia",
            "Abdominal hernia",
            "Diaphragmatic hernia",
            "Femoral hernia",
            "Cellulitis"
        ],
        "Skin and soft tissue infections": [
            "Folliculitis",
            "Superficial abscess",
            "Impetigo",
            "Wound infection",
            "Erysipelas",
            "Appendicitis"
        ],
        "Intestinal infection or inflammation": [
            "Diverticulitis",
            "Inflammatory bowel disease",
            "Inflammatory biliary disease (cholecystitis)",
            "Colitis (c. difficile)"
        ]
    }
}

export default Data;