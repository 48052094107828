import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import data from "./data"

let lastId = 0;

class row {
  procedure?: string | null;
  category?: string | null;
  type?: string | null;
  procedureInputValue: string = '';
  categoryInputValue: string = '';
  typeInputValue: string = ''
  id: number;

  constructor(p: string | null = null, c: string | null = null, t: string | null = null){
    this.procedure = p;
    this.category = c;
    this.type = t;
    this.id = lastId + 1
    lastId += 1
  }
}

export default function SamTable() {
  const [rows, setRows] = useState<{[key: string]: row}>({})

  const addRow = () => {
    let newRow = new row();
    setRows({...rows, [`${newRow.id}`]: newRow})
    console.log("row added")
  }

  const setProcedure = (value: string | null, id: number) => {
    console.log(`Setting Procedure for ${id} to ${value}`)
    let currRow = rows[`${id}`]
    currRow.procedure = value
    console.log(currRow)
    setRows({...rows, [`${id}`]: currRow})
  }

  const setCategory = (value: string | null, id: number) => {
    console.log(`Setting Category for ${id} to ${value}`)
    let currRow = rows[`${id}`]
    currRow.category = value
    console.log(currRow)
    setRows({...rows, [`${id}`]: currRow})
  }

  const setType = (value: string | null, id: number) => {
    let currRow = rows[`${id}`]
    currRow.type = value
    setRows({...rows, [`${id}`]: currRow})
  }

  const setProcedureInputValue = (value: string, id: number) => {
    let currRow = rows[`${id}`]
    currRow.procedureInputValue = value
    console.log(currRow)
    setRows({...rows, [`${id}`]: currRow})
  }

  const setCategoryInputValue = (value: string, id: number) => {
    let currRow = rows[`${id}`]
    currRow.categoryInputValue = value
    console.log(currRow)
    setRows({...rows, [`${id}`]: currRow})
  }

  const setTypeInputValue = (value: string, id: number) => {
    let currRow = rows[`${id}`]
    currRow.typeInputValue = value
    console.log(currRow)
    setRows({...rows, [`${id}`]: currRow})
  }


  const procedureOptions = Object.keys(data)

  const getCategories = (procedure: string | null | undefined) => {
    if (procedure == null || !procedure) {
      return []
    }
    if(procedure == "procedures"){
      return Object.keys(data.procedures);
    }
    if (procedure == "conditions") {
      return Object.keys(data.conditions)
    }
    else {
      return []
    }
  }

  const getTypes = (procedure: string | null | undefined, category: string | null | undefined) => {
    if (procedure == null || !procedure) {
      return []
    }
    if (procedure == "presentations") {
      return data.presentations
    }
    if(category == null || !category) {
      return []
    }
    if(procedure == "procedures"){
      return (data.procedures as any)[category];
    }
    if (procedure == "conditions") {
      return (data.conditions as any)[category]
    }
    else {
      return []
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Procedure/Condition/Presentation</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(rows).map((currRow: row) => (
            <TableRow key={currRow.id}>
              <TableCell align="center">
                <Autocomplete
                    disablePortal
                    value={currRow.procedure}
                    onChange={(_: any, newValue: string | null) => {
                      setProcedure(newValue, currRow.id);
                    }}
                    inputValue={currRow.procedureInputValue}
                    onInputChange={(_, newInputValue) => {
                      setProcedureInputValue(newInputValue, currRow.id);
                    }}
                    id={`procedure-${currRow.id}`}
                    options={procedureOptions}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} label="Procedure" />} />
              </TableCell>
              <TableCell align="center">
                <Autocomplete
                        disablePortal
                        value={currRow.category}
                        onChange={(_: any, newValue: string | null) => {
                          setCategory(newValue, currRow.id);
                        }}
                        inputValue={currRow.categoryInputValue}
                        onInputChange={(_, newInputValue) => {
                          setCategoryInputValue(newInputValue, currRow.id);
                        }}
                        id={`categories-${currRow.id}`}
                        options={getCategories(currRow.procedure)}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label="Categories" />} />
              </TableCell>
              <TableCell align="center">
                <Autocomplete
                          disablePortal
                          value={currRow.type}
                          onChange={(_: any, newValue: string | null) => {
                            setType(newValue, currRow.id);
                          }}
                          inputValue={currRow.typeInputValue}
                          onInputChange={(_, newInputValue) => {
                            setTypeInputValue(newInputValue, currRow.id);
                          }}
                          id={`types-${currRow.id}`}
                          options={getTypes(currRow.procedure, currRow.category)}
                          sx={{ width: 200 }}
                          renderInput={(params) => <TextField {...params} label="Types" />} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={addRow}>Add Row</Button>
    </TableContainer>
  );
}

